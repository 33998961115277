.MuiButton-outlinedPrimary{
    color: black !important;
}

.MuiButton-outlinedPrimary:hover{
    color: white !important;
}

.MuiFab-primary {
    background-color: @buttonHoverBackground !important;
  }

.MuiButton-root:hover {
    color: white;
    background: @buttonHoverBackground !important;
}

.MuiInput-root input[type="date"]{
    margin-top: 1em !important;
}

.MuiCircularProgress-colorPrimary{
    color: @buttonHoverBackground !important;;
}

.Mui-checked{
    color: @buttonHoverBackground !important;
}

.MuiTextField-root{
    width: 100%;
    min-width: 10em !important;
}

.material-icons {
    font-size: 1.2em;
    margin-right: .5em;
}

.MuiTab-root:hover{
    color: darkgray;
}

.MuiTab-textColorPrimary{
    padding: 12px 16px !important;
}

.MuiPaper-root{
    padding: 1em;
    margin-top: 1em;
}
