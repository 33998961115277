.fileviewer-dialog {
    .uploaded-file{
        width: 100%;
        height: 100%;
    }

    .uploaded-file-file{
        font-size: 5em;
        text-align: center;
    }

    .uploaded-file-caption{
        font-size: .7em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .download-button{
      text-align: center;
      margin-bottom: 1em;
    }
}
