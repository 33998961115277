.MuiButton-outlinedPrimary {
  color: black !important;
}
.MuiButton-outlinedPrimary:hover {
  color: white !important;
}
.MuiFab-primary {
  background-color: #a90004 !important;
}
.MuiButton-root:hover {
  color: white;
  background: #a90004 !important;
}
.MuiInput-root input[type="date"] {
  margin-top: 1em !important;
}
.MuiCircularProgress-colorPrimary {
  color: #a90004 !important;
}
.Mui-checked {
  color: #a90004 !important;
}
.MuiTextField-root {
  width: 100%;
  min-width: 10em !important;
}
.material-icons {
  font-size: 1.2em;
  margin-right: 0.5em;
}
.MuiTab-root:hover {
  color: darkgray;
}
.MuiTab-textColorPrimary {
  padding: 12px 16px !important;
}
.MuiPaper-root {
  padding: 1em;
  margin-top: 1em;
}
header .logo {
  font-size: 2em;
  margin-left: 2em;
}
@media (max-width: 1000px) {
  header .logo {
    font-size: 1.2em;
    margin-top: 0.4em;
    margin-left: 2.5em;
  }
}
header .logo-red {
  color: #f8f2ee;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
  margin-top: 0.7em;
}
header .logo-icon {
  margin-top: 0.7em;
}
header .logo-icon img {
  height: 1.4em;
  border-radius: 10em;
}
header .logo-white {
  color: white;
  margin-left: 0.2em;
}
header .product-title {
  color: #f8f2ee;
  margin-left: 1em;
  margin-top: 0.7em;
}
header .wrapper {
  justify-content: space-between;
  width: 100%;
  background-color: #3f3f3f;
  height: 6em;
}
@media (max-width: 1000px) {
  header .wrapper {
    height: 4em;
  }
}
header .link-back-login {
  padding-left: 2em;
  padding-top: 1.8em;
}
@media (max-width: 1000px) {
  header .link-back-login {
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1.1em;
  }
}
header .back-login {
  font-size: 2em;
  color: white;
}
header .btn-logout {
  border: solid black 0.1em;
  padding: 0.5em 1em;
  color: black;
  margin: auto;
  width: 75px;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
  background: #f8f2ee;
  float: right;
  margin-top: 1.8em;
  margin-right: 3em;
}
@media only screen and (max-width: 1000px) {
  header .btn-logout {
    display: none;
  }
}
header .need-help {
  color: white;
  margin-top: 2.3em;
  margin-right: 1em;
  cursor: pointer;
}
@media (max-width: 1000px) {
  header .need-help {
    margin-top: 1.3em;
    display: none;
  }
}
header .need-help:hover {
  color: #a90004;
}
#toast {
  position: fixed;
  top: 7em;
  width: 30em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.95;
  z-index: 99;
}
#toast .title {
  font-size: 1.2em;
  font-weight: bold;
  display: inline;
  margin-right: 1em;
}
#toast .toast {
  padding: 1em;
}
#toast .success {
  background-color: #61b961;
}
#toast .error {
  background-color: #ce696b;
}
#toast .message {
  display: inline;
}
#toast .close-button {
  position: absolute;
  top: 0.1em;
  right: 0.2em;
  opacity: 0.6;
}
#toast .fa {
  color: white;
  font-size: 1.2em;
}
#app-error {
  display: inline-block;
  width: 100%;
}
#app-error .error-message {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10em;
  width: 30%;
  background-color: #ce696b;
  padding: 2em;
}
#loader {
  display: inline-block;
  width: 100%;
}
#loader .load-icon {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 2em;
  padding: 2em;
  font-size: 4em;
}
#cookie-policy .cookie-policy {
  position: fixed;
  bottom: 0;
  color: white;
  background: #989797;
  padding: 1em;
  z-index: 1;
}
#cookie-policy .cookie-policy-text {
  width: 88%;
}
#sidebar {
  background: #2f2e2e;
  height: 100%;
  min-height: 100em;
  top: 6em;
  position: absolute;
  border: 0px solid #000000;
}
#sidebar .module {
  white-space: pre-wrap;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border: 0px solid #000000;
  color: #cccccc;
  height: 4em;
  text-align: left;
  padding: 1em;
  width: 15em;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#22262b+0,091324+100 */
  text-decoration: none;
  cursor: pointer;
}
#sidebar .module a:link {
  color: #cccccc;
}
#sidebar .module a:hover {
  color: white;
}
#sidebar .module.active {
  background: #f8f2ee !important;
  color: black !important;
}
#sidebar .module.active a:hover {
  color: black;
}
#sidebar .selectedBuildingName {
  font-weight: bold;
}
#sidebar .linkStyle {
  width: 13em;
}
@media (max-width: 1000px) {
  #sidebar .linkStyle {
    padding-right: 2em;
  }
}
#sidebar .complete-check {
  position: absolute;
  color: green;
  left: 8em;
  bottom: 2.1em;
  font-size: 1.8em;
}
#sidebar div {
  white-space: pre-wrap;
}
#sidebar div a:link {
  color: white;
}
#sidebar div a:hover {
  color: white !important;
}
#sidebar .showNav-bar {
  position: absolute;
  top: 0.9em;
  left: 1.5em;
  font-size: 2em;
  color: white;
}
@media (max-width: 1000px) {
  #sidebar .showNav-bar {
    top: 0.6em;
  }
}
#sidebar .mobile-nav {
  position: absolute;
  top: 4em;
  background-color: black;
  z-index: 99;
}
#sidebar .desktop {
  display: block;
}
#sidebar .tablet {
  display: none;
}
#sidebar .tablet .btn {
  height: 7em;
  font-size: 14px;
  display: block;
}
#sidebar .tablet .complete-check {
  left: 8em;
  height: 1.2em;
}
@media (max-width: 1000px) {
  #sidebar .desktop {
    display: none;
  }
  #sidebar .tablet {
    display: block;
  }
}
@media (max-width: 1000px) {
  #sidebar {
    width: 0;
    top: 0;
  }
}
.menu .opener {
  font-size: 1.5em;
  background-color: white;
  color: black;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
}
.menu .opener:hover {
  color: darkgray;
}
.menu .open {
  display: none;
}
.menu-item {
  font-size: 1.2em;
  background-color: white;
  color: black;
  padding: 0.5em 1em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  width: 12em;
}
.menu-item:hover {
  color: darkgray;
}
#emergencyManagementPlanDetail .action {
  margin-top: 1em;
  margin-right: 2em;
}
#emergencyManagementPlanDetail .btn-archive {
  margin-left: 5em;
}
@media only screen and (max-width: 1000px) {
  #emergencyManagementPlanDetail .btn-archive {
    margin-left: 0;
  }
}
#emergencyManagementPlanDetail .btn-archive-save {
  margin-left: 7em;
  margin-top: 1em;
}
#emergencyManagementPlanDetail .btn-reopen {
  margin-left: 5em;
}
#emergencyManagementPlanDetail .pdf {
  margin-right: 1em;
  cursor: pointer;
}
#emergencyManagementPlans {
  color: black;
}
#emergencyManagementPlans .institutionName {
  font-size: 0.5em;
}
#emergencyManagementPlans .create-emergency-plan-button-holder {
  margin: 1em;
}
#emergencyManagementPlans .emergencyManagementPlan-link {
  display: inline-flex;
  width: 100%;
}
#emergencyManagementPlans .emergencyManagementPlan {
  padding: 1em 1em 0.4em;
  background-color: white;
  box-shadow: 0.1em 0.1em 0.01em #d9d7db;
  margin-top: 0.5em;
  color: black;
  justify-content: space-between;
}
#emergencyManagementPlans .emergencyManagementPlan:hover {
  background-color: #d9d7db;
}
#emergencyManagementPlans .emergencyManagementPlans {
  margin: 0 0 5em 0;
}
#emergencyManagementPlans .planIcon {
  font-size: 1em;
  margin-right: 0.5em;
}
#emergencyManagementPlans .emergencyManagementPlanName {
  display: inline-block;
  width: 80%;
  font-size: 1.5em;
}
#emergencyManagementPlans .emergencyManagementPlanInstitutionName {
  margin: 0 2em;
}
#emergencyManagementPlans .excel {
  cursor: pointer;
  justify-content: right;
}
#templateModule form {
  margin-bottom: 3em;
}
#templateModule .moduleText {
  margin-right: 1em;
}
#templateModule h2 {
  color: black;
}
#templateModule div {
  margin-bottom: 1em;
}
#templateModule [contenteditable="true"]:focus {
  border: none;
  outline: none;
}
#templateModule .notes {
  margin-top: 0;
}
#templateModule .inline-input {
  min-width: 20em;
  margin-left: 0.5em;
}
#templateModule .impact-label {
  width: 10em;
}
#templateModule .impact {
  width: 30em;
  margin-left: 0.5em;
}
#templateModule .full-width {
  margin-right: 1em;
}
#templateModule img {
  width: 80%;
}
#templateModule .btn-upload {
  width: 10em;
  margin-top: 1em;
}
#templateModule .image {
  position: relative;
  margin-bottom: 2em;
  image-orientation: from-image;
  max-width: 50em;
  height: 50em;
}
#templateModule .uploaded-file-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
#templateModule .uploaded-file {
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
  height: 1em;
  width: 1em;
}
#templateModule .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#templateModule .delete-image {
  position: absolute;
  top: -0.7em;
  right: -0.7em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
}
#templateModule .dropzonearea {
  width: 90%;
}
#templateModule .templateModuleRow .input {
  background: white;
  padding: 0.2em;
}
#templateModule .templateModuleRow .input div {
  margin-bottom: 0;
}
#templateModule .templateModuleRow .row-action {
  width: 6em;
  font-size: 1.1em;
  padding: 0.3em;
}
#templateModule .templateModuleRow .add {
  margin-right: 1em;
}
#templateModule .templateModuleRow .header {
  margin-bottom: 0;
  font-weight: bold;
}
#templateModule .templateModuleRow .row {
  justify-content: space-between;
}
#templateModule .templateModuleRow .adds {
  position: fixed;
  right: 0.5em;
  top: 8em;
  border: solid 0.01em;
  background: lightgray;
  padding: 1em;
}
@media (max-width: 600px) {
  #templateModule .templateModuleRow .adds {
    right: 0;
    padding: 0.5em;
  }
}
#templateModule .templateModuleRow .committeeMember-header,
#templateModule .templateModuleRow .committeeMember-input {
  margin-right: 2em;
  width: 20%;
  min-width: 4em;
}
@media (max-width: 600px) {
  #templateModule .templateModuleRow .committeeMember-header,
  #templateModule .templateModuleRow .committeeMember-input {
    width: 100%;
    margin-bottom: 0.5em;
  }
}
#templateModule .templateModuleRow .evacuationLocation {
  width: 30em;
}
#templateModule .templateModuleNarrative .input {
  background: white;
  padding: 0.2em;
  width: 15em;
  margin-right: 1em;
}
#templateModule .templateModuleNarrative .input div {
  margin-bottom: 0;
}
#certificateModule div {
  margin-bottom: 1em;
}
#certificateModule .image {
  position: relative;
  margin-bottom: 2em;
  image-orientation: from-image;
  max-width: 50em;
  height: 50em;
}
#certificateModule .uploaded-file-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
#certificateModule .uploaded-file {
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
  height: 1em;
}
#certificateModule .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#certificateModule .delete-image {
  position: absolute;
  top: -0.7em;
  right: -0.7em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
}
#certificateModule .dropzonearea {
  width: 90%;
}
#safetyEventModule .title {
  justify-content: space-between;
}
#safetyEventModule .print {
  cursor: pointer;
}
#safetyEventModule h2 {
  margin-top: 2em;
}
#safetyEventModule .history-paper {
  margin-top: 1em;
}
#safetyEventModule .history {
  padding: 1em;
}
#safetyEventModule .history h3 {
  margin: 0;
}
#safetyEventModule .width-22 {
  margin-top: 0.7em;
}
#safetyEventModule .save {
  margin-top: 1.5em;
}
@media print {
  body * {
    visibility: hidden;
  }
  .content * {
    visibility: visible;
  }
  .history {
    width: 100%;
  }
  .history,
  .history * {
    visibility: visible;
  }
  .history .no-print,
  .history * .no-print,
  .history .no-print *,
  .history * .no-print * {
    visibility: hidden;
  }
}
.safetyEventEntry .attendee-toggle {
  cursor: pointer;
}
.safetyEventEntry .addAnotherOther {
  font-size: 0.8em;
  color: darkgray;
  margin-top: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
.safetyEventEntry .addOtherName {
  width: 50%;
}
.safetyEventEntry .subtype {
  width: 100%;
}
.safetyEventEntry .flex-row {
  justify-content: space-between;
}
.safetyEventEntry .MuiSelect-select {
  margin-top: 1em !important;
}
@media (max-width: 1000px) {
  .safetyEventEntry .width-22 {
    min-width: 10em;
  }
}
.studentReunification .paper {
  padding: 1em;
}
.studentReunification .flex-row {
  width: 100%;
  margin-top: 1em;
}
.studentReunification .flex-col {
  padding: 0.5em;
}
@media (max-width: 600px) {
  .studentReunification .flex-col {
    flex-grow: 0;
  }
}
.studentReunification .flex-row.top-connected {
  margin-top: 0.5em;
}
.studentReunification .section {
  margin-top: 1em;
}
.studentReunification .full-width {
  width: 100%;
}
.studentReunification .row .flex-col {
  margin-right: 1em;
}
.studentReunification .textbox-label {
  margin-bottom: 0.5em;
}
.studentReunification ul {
  list-style-type: circle;
}
.studentReunification ul,
.studentReunification ol {
  padding: 0 1em;
  margin: 0;
}
.studentReunification li {
  padding-left: 1em;
}
.studentReunification .small-cols .MuiTextField-root {
  width: 10em;
}
.studentReunification .med-col .MuiTextField-root {
  width: 20em;
}
.studentReunification .large-col .MuiTextField-root {
  width: 30em;
}
.studentReunification .data-col {
  margin-top: 0.2em;
  width: 50em;
}
.studentReunification .row-title {
  margin-top: 1em;
  width: 10em;
}
.studentReunification .big-img {
  width: 70%;
}
.studentReunification .image {
  position: relative;
  margin-bottom: 2em;
  image-orientation: from-image;
  max-width: 50em;
  height: 50em;
}
.studentReunification .assets {
  margin-top: 1em;
}
.studentReunification .uploaded-folder-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
.studentReunification .uploaded-file-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
.studentReunification .uploaded-file {
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
  height: 1em;
  width: 1em;
}
.studentReunification .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.studentReunification .delete-image {
  position: absolute;
  top: -0.7em;
  right: -0.7em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
}
.share-dialog {
  padding: 0 2em 2em 2em;
  text-align: center;
}
.share-dialog .shareEmail {
  min-width: 20em;
}
.share-dialog .share-dialog-email {
  margin-top: 1em;
}
.share-dialog .share-button-container {
  margin-top: 1em;
}
@media (max-width: 600px) {
  .siteVisit .MuiTab-root {
    min-width: 1em;
    max-width: 6.5em;
  }
}
.siteVisit .paper {
  padding: 1em;
}
.siteVisit .flex-row {
  width: 100%;
  margin-top: 1em;
}
.siteVisit .short {
  width: 30em;
}
@media (max-width: 600px) {
  .siteVisit .flex-col {
    flex-grow: 0;
  }
}
.siteVisit .flex-row.top-connected {
  margin-top: 0.5em;
}
.siteVisit .tab-action-row {
  justify-content: space-between;
}
.siteVisit .actions {
  margin-top: 0;
}
.siteVisit .section {
  margin-top: 5em;
}
.siteVisit .question {
  font-weight: bold;
}
.siteVisit .full-width {
  width: 100%;
}
.siteVisit .row .flex-col {
  margin-right: 1em;
}
.siteVisit .textbox-label {
  margin-bottom: 0.5em;
}
.siteVisit .top-actions {
  justify-content: space-between;
}
.siteVisit ul {
  list-style-type: circle;
}
.siteVisit ul,
.siteVisit ol {
  padding: 0 1em;
  margin: 0;
}
.siteVisit li {
  padding-left: 1em;
}
.siteVisit .small-cols .MuiTextField-root {
  width: 10em;
}
.siteVisit .med-col .MuiTextField-root {
  width: 20em;
}
.siteVisit .large-col .MuiTextField-root {
  width: 30em;
}
.siteVisit .data-col {
  margin-top: 0.2em;
  width: 50em;
}
.siteVisit .row-title {
  margin-top: 1em;
  width: 10em;
}
.siteVisit .big-img {
  width: 70%;
}
.siteVisit .image {
  position: relative;
  margin-bottom: 2em;
  image-orientation: from-image;
  max-width: 50em;
  height: 50em;
}
.siteVisit .assets {
  margin-top: 1em;
}
.siteVisit .uploaded-folder-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
.siteVisit .uploaded-file-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
.siteVisit .uploaded-file {
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
  height: 1em;
  width: 1em;
}
.siteVisit .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.siteVisit .delete-image {
  position: absolute;
  top: -0.7em;
  right: -0.7em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
}
.siteVisit .dropzonearea {
  justify-content: center;
}
@media (max-width: 800px) {
  .siteVisit .dropzonearea .MuiDropzoneArea-root {
    border-radius: 50em;
    max-width: 17em;
  }
}
.share-dialog {
  padding: 0 2em 2em 2em;
  text-align: center;
}
.share-dialog .shareEmail {
  min-width: 20em;
}
.share-dialog .share-dialog-email {
  margin-top: 1em;
}
.share-dialog .share-button-container {
  margin-top: 1em;
}
#auditModule .rating-row {
  width: 80%;
  justify-content: space-evenly;
}
#auditModule .rating-title {
  font-size: 2em;
  font-weight: 300;
}
#auditModule .score {
  font-size: 1.5em;
}
#auditModule .question {
  margin-bottom: 1em;
  width: 80%;
}
@media (max-width: 1000px) {
  #auditModule .question {
    width: 100%;
  }
}
#auditModule .audit-label {
  font-weight: bold;
}
#auditModule .flex-row {
  margin-top: 0.5em;
}
#auditModule .audit-section {
  margin-right: 3em;
}
#auditModule .audit-column {
  width: 15em;
}
#auditModule .citation-text {
  max-width: 30em;
}
#auditModule .answer-number {
  width: 2em;
}
#auditModule .comments {
  width: 50em;
}
@media (max-width: 1000px) {
  #auditModule .comments {
    width: 30em;
  }
}
#policies .policy-link {
  display: inline-flex;
  width: 100%;
}
#policies .policy {
  border: solid #9a9a9a 1px;
  padding: 1em 1em 0.4em 1em;
  background-color: white;
  box-shadow: 0.1em 0.1em 0.01em #d9d7db;
  margin-top: 0.5em;
  color: black;
  justify-content: space-between;
}
#policies .policy:hover {
  background-color: #d9d7db;
}
#policies .policyTitle {
  display: inline-block;
  width: 70%;
  font-size: 1.5em;
}
#policies .complete-check-container {
  position: relative;
  margin-right: 1em;
  color: green;
}
#policies .fa-check-circle {
  color: green;
}
#policies .check-placeholder {
  display: inline-block;
  width: 1em;
}
#policy ul {
  list-style-type: disc;
  padding-left: 2em;
}
#policy li {
  margin-top: 0.5em;
}
#policy .justification {
  margin-bottom: 1em;
}
#policy .subPolicy {
  margin-bottom: 2em;
}
#policy .acknowledged {
  margin-top: 1em;
  max-width: 20em;
  justify-content: space-between;
}
#policy .acknowledge-check {
  margin-top: 0.5em;
}
#policy .complete-check-container {
  position: relative;
  color: green;
}
#policy .fa-check-circle {
  color: green;
}
#policy .save-comments {
  margin: 0;
  width: 1em;
}
#assets a {
  color: black;
}
#assets .btn-upload {
  width: 10em;
  margin: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}
#assets .image {
  position: relative;
  margin-bottom: 2em;
  image-orientation: from-image;
  max-width: 50em;
  height: 50em;
}
#assets .assets {
  margin-top: 1em;
}
#assets .back-folder {
  margin-bottom: 1em;
}
#assets .uploaded-folder-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
#assets .uploaded-file-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
}
#assets .uploaded-folder {
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
}
#assets .uploaded-file {
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
  height: 1em;
  width: 1em;
}
#assets .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#assets .delete-image {
  position: absolute;
  top: -0.7em;
  right: -0.7em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
}
#assets .fa-level-up {
  cursor: pointer;
  font-size: 1.1em;
}
#assets .dropzonearea,
#assets .input-field {
  width: 50%;
}
.AddRemove.flex-row {
  margin-top: 0;
}
.AddRemove .flex-col {
  margin-right: 0 !important;
}
.fileviewer-dialog .uploaded-file {
  width: 100%;
  height: 100%;
}
.fileviewer-dialog .uploaded-file-file {
  font-size: 5em;
  text-align: center;
}
.fileviewer-dialog .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileviewer-dialog .download-button {
  text-align: center;
  margin-bottom: 1em;
}
a {
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: none !important;
}
html {
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
html,
body {
  height: 100%;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.wrapper {
  width: 100%;
  background-color: #3f3f3f;
  height: 6em;
}
.content {
  min-height: 100% !important;
  width: 100%;
}
.social-wrapper {
  height: 3em;
}
.footerSection {
  width: 100%;
  background-color: #3f3f3f;
  color: black;
  font-size: 0.75em;
  height: 12em;
  margin-top: -12em;
}
.copyright {
  margin: auto;
  padding-top: 5.3em;
  width: 600px;
  text-align: center;
}
.btn-primary {
  border-radius: 10px !important;
  background: #f8f2ee !important;
  color: black;
  width: 100%;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-style: none;
}
.btn-primary:hover {
  background: #3f3f3f;
}
select.input-field {
  height: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
  box-sizing: content-box;
  width: 100%;
  color: black;
}
textarea.input-field {
  border-style: solid;
  border: none;
  border-color: black;
  border-width: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1em;
  box-sizing: content-box;
  padding: 1em;
  width: 90%;
  color: black;
}
@media print {
  body * {
    visibility: hidden;
  }
  header {
    display: none;
  }
  .content * {
    visibility: visible;
  }
  .no-print,
  .no-print * {
    visibility: hidden;
  }
}
input.input-field {
  border-style: solid;
  border: none;
  border-color: black;
  border-width: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1em;
  box-sizing: content-box;
  padding: 1em;
  width: 90%;
  color: black;
}
.btn-holder {
  width: 100px;
  margin: auto;
}
.other-btn {
  margin-left: 2em;
}
.main-container {
  min-height: 75em;
  background: #f5f5f5;
  border: 0px solid #000000;
  padding-left: 18em;
  padding-right: 2em;
  padding-bottom: 5em;
  padding-top: 1em;
  width: 100%;
}
.main-container.no-sidebar {
  padding-left: 2em;
  padding-right: 2em;
}
@media (max-width: 1000px) {
  .main-container {
    padding: 0.5em;
  }
}
h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 1.5em;
}
p {
  padding: 0.25em 0 1em 0;
  color: black;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.btn-first {
  border: 0px solid #000000;
}
.btn-active {
  background: #f8f2ee !important;
  color: #3f3f3f !important;
}
.initial-btn-active {
  background: #f8f2ee !important;
  color: #3f3f3f !important;
}
.btn {
  width: 275px;
  border-radius: 0px 0px 0px 0px;
  border: 0px solid #000000;
  color: black;
  height: 100px;
  text-align: left;
  padding: 1em;
  padding-top: 1.25em;
  padding-left: 2em;
}
.main-content {
  width: 100%;
  background: #3b4d58;
  height: 100%;
  border: 0px solid #000000;
  padding: 2em;
}
.btn-next,
.btn-back {
  border: solid black 0.1em;
  padding: 0.5em 1em;
  color: black;
  margin: auto;
  min-width: 75px;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
}
.btn-next:hover,
.btn-back:hover,
.btn-logout:hover {
  text-decoration: none;
  color: white;
  background: #a90004;
}
.question {
  color: black;
  padding-top: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.answer-area {
  width: 852px;
  background-color: #3f3f3f;
  margin-bottom: 2em;
  padding: 1em;
  margin-top: 1em;
}
.answer-area span {
  line-height: 2em;
}
.answer-text {
  padding-left: 1em;
}
.complete-message {
  margin: auto;
  color: black;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.message-box {
  width: 325px;
  height: 80px !important;
  margin-top: 1em!important;
  margin: auto;
  padding-top: 1em;
  border-radius: 1%;
  background-color: #f5f5f5 !important;
  -webkit-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  padding-bottom: 7em!important;
  text-align: center;
}
.complete-box {
  width: 325px;
  height: 80px !important;
  margin-top: 1em!important;
  margin: auto;
  padding-top: 2em;
  border-radius: 1%;
  background-color: #f5f5f5 !important;
  -webkit-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.61);
  padding-bottom: 8em!important;
  text-align: center;
}
.message-holder {
  width: 260px;
  padding: 0;
  margin: auto;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  padding-left: 0;
}
li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.successMessage {
  font-weight: bold;
  color: green;
}
.errorMessage {
  font-weight: bold;
  color: black;
}
label {
  margin-right: 0.5em;
}
.width-22,
.width-30,
.width-44 {
  min-width: 4em;
}
.width-22 {
  width: 21%;
}
.width-30 {
  width: 29%;
}
.width-44 {
  width: 43%;
}
select {
  height: 1.8em;
}
.section {
  margin-top: 1em;
}
.big-section {
  margin-top: 2em;
}
.header {
  font-weight: bold;
}
#menu-col {
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 99;
}
.menu {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 600px) {
  .menu {
    top: -5em;
  }
}
.paper {
  margin-bottom: 1em;
  padding: 1em;
}
.paper h2 {
  margin-top: 0;
}
@media (max-width: 1000px) {
  .hide-phone {
    display: none;
  }
}
