@import "./color";
@import "./Material";
@import "./Home";
@import "./Header";
@import "./Message";
@import "./AppError";
@import "./Loader";
@import "./CookiePolicy";
@import "./Sidenav";
@import "./DotMenu";
@import "./DotMenuItem";
@import "./emergencyManagementDetail";
@import "./emergencyManagementList";
@import "./templateModule";
@import "./templateModuleRow";
@import "./templateModuleNarrative";
@import "./Certificate";
@import "./safetyEventModule";
@import "./safetyEventEntry";
@import "./studentReunification";
@import "./SiteVisit";
@import "./emergencyManagementAudit.less";
@import "./Policies.less";
@import "./Policy.less";
@import "./File.less";
@import "./AddRemove.less";
@import "./FileViewer";


a {
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none !important;
}


html
{
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body
{
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

}

html,
body {
  height: 100%;
}

.flex {
  display: flex
}

.flex-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 100%;
  background-color: @background;
  height: 6em;
}

.content {
  min-height: 100% !important;
  width: 100%;
}

.social-wrapper {
  height: 3em;
}

.footerSection{
  width: 100%;
  background-color: @background;
  color: @textcolor;
  font-size: .75em;
  height: 12em;
  margin-top: -12em;
 }

.copyright {
  margin: auto;
  padding-top: 5.3em;
  width: 600px;
  text-align: center;
}

.btn-primary {
  border-radius: 10px !important;
  background: @headlines !important;
  color: @textcolor;
  width: 100%;
  margin: auto;
  padding-top: .5em;
  padding-bottom: .5em;
  border-style: none;
}

.btn-primary:hover {
  background: @background;
}

select.input-field{
  height: 3em;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  box-sizing: content-box;
  width: 100%;
  color: black;
}

textarea.input-field {
  border-style:solid;
  border:none;
  border-color: black;
  border-width:1px;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1em;
  box-sizing: content-box;
  padding: 1em;
  width: 90%;
  color: black;
}


@media print {
  body * {
    visibility: hidden;
  }

  header{
    display: none;
  }

  .content * {
    visibility: visible;
  }

  .no-print, .no-print * {
    visibility: hidden;
  }  
}

input.input-field {
  border-style:solid;
  border:none;
  border-color: black;
  border-width:1px;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1em;
  box-sizing: content-box;
  padding: 1em;
  width: 90%;
  color: black;
}

.btn-holder {
  width: 100px;  
  margin: auto;
}

.other-btn{
  margin-left: 2em;
}

  .main-container {
    min-height: 75em;
    background: @frontground;
    border: 0px solid #000000;
    padding-left: 18em;
    padding-right: 2em;
    padding-bottom: 5em;
    padding-top: 1em;
    width:100%
  }

  .main-container.no-sidebar{
    padding-left: 2em;
    padding-right: 2em;
  }

  @media (max-width : 1000px) {
    .main-container {
      padding: .5em;
    }
  }

  h1 {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: 300;
    color: @textcolor;
    font-size: 2em;
    margin-top: .5em;
    margin-bottom: .5em;
   }
 
   h2 {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: 300;
    color: @textcolor;
    font-size: 1.5em;
   }

  p {
    padding: .25em 0 1em 0;
    color: @text;
    line-height: 1.5em;
    letter-spacing: .1em;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 

  }

  

 
  .btn-first {
    border: 0px solid #000000;
  }

  .btn-active {
    background: @headlines !important;
    color: @background !important;
  }

  .initial-btn-active {
    background: @headlines !important;
    color: @background !important;
  }

  .btn {
    width: 275px;
    border-radius: 0px 0px 0px 0px;
    border: 0px solid #000000;
    color: @textcolor;
    height: 100px;
    text-align: left;
    padding: 1em;
    padding-top: 1.25em;
    padding-left: 2em;
  }


  .main-content{
        width: 100%;
        background: #3b4d58;
        height: 100%;
        border: 0px solid #000000;
        padding: 2em;
  }

  .btn-next,.btn-back {
    border: solid black 0.1em;
    padding: .5em 1em;
    color: @textcolor;
    margin: auto;
    min-width: 75px;
    text-align: center;
    padding: .5em;
    cursor: pointer;
    text-decoration: none;
  }

  .btn-next:hover,.btn-back:hover,.btn-logout:hover{
    text-decoration: none;
    color: white;
    background: @buttonHoverBackground;
  }

  .question {
    color: @text;
    padding-top: 1em;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 

  }

  .answer-area {
      width: 852px;
      background-color:@background;
      margin-bottom: 2em;
      padding: 1em;
      margin-top: 1em;

      span{
        line-height: 2em;
      }
  }

  .answer-text{
    padding-left: 1em;
  }

  .complete-message {
    margin: auto;
    color: @textcolor;;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  }

  .message-box {
    width: 325px;
    height: 80px !important;
    margin-top: 1em!important;
    margin: auto;
    padding-top: 1em;
    border-radius: 1%;
    background-color: @frontground!important;
    -webkit-box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    -moz-box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    padding-bottom: 7em!important;
    text-align: center;
  } 

  .complete-box {
    width: 325px;
    height: 80px !important;
    margin-top: 1em!important;
    margin: auto;
    padding-top: 2em;
    border-radius: 1%;
    background-color: @frontground!important;
    -webkit-box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    -moz-box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.61);
    padding-bottom: 8em!important;
    text-align: center;
  } 
  .message-holder {
    width: 260px;
    padding: 0;
    margin: auto;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    padding-left: 0;
  }

  li a {
      display: block;
      color: @link;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
  }

  .successMessage{
    font-weight: bold;
    color: green;
  }

  .errorMessage{
    font-weight: bold;
    color: @textcolor;;
  }

  label{
    margin-right: .5em;
  }
 
  .width-22,  .width-30, .width-44 {
    min-width: 4em;
  }

  .width-22 {
    width: 21%;
      
  }

  .width-30 {
    width: 29%;
  }

  .width-44 {
    width: 43%; 
  }

  select {
    height: 1.8em;
  }

  .section{
    margin-top:1em;
  }

  .big-section{
    margin-top:2em;
  }

  .header{
    font-weight: bold;
  }

  #menu-col{
    position: relative;
    display: inline-block;
    width: 100%;
    z-index: 99;
  }

  .menu{
      position: absolute;
      right: 0;
      top: 0;

      @media (max-width : 600px) {
        top: -5em;
      }  
  }

  .paper {
    margin-bottom: 1em;
    padding: 1em;

    h2{
      margin-top: 0;
    }
  }

  
  @media (max-width : 1000px) {
    .hide-phone {
      display: none;
    }
  }  
